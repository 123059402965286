import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const AboutSectionContainer = styled.section`
  width: 100%;
  color: black;
  text-align: center;
  padding: 0 20px; /* Remove top padding */
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  background-color: none; /* Ensure same background color */

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const LeftSection = styled.div`
  flex: 1;
  padding: 20px;
  max-width: 100%;
  position: relative;
  z-index: 1;
  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: -2%;
    width: 10px;
    height: 60%;
    background-color: green;
    z-index: 2;
  }

  @media (min-width: 768px) {
    max-width: 50%;
  }
`;

const RightSection = styled.div`
  flex: 1;
  padding: 20px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
  position: relative;
  z-index: 1;
  &::before {
    content: '';
    position: absolute;
    top: 20%;
    left: -2%;
    width: 10px;
    height: 70%;
    background-color: orange;
    z-index: 2;
  }

  @media (min-width: 768px) {
    max-width: 50%;
  }
`;

const AboutTitle = styled(motion.h1)`
  font-size: 36px;
  line-height: 1.2;
  margin-bottom: 20px;
  color: green;
`;

const AboutText = styled(motion.p)`
  font-size: 18px;
  line-height: 1.5;
  margin-bottom: 20px;
  position: relative;
`;

const AboutImage = styled(motion.img)`
  width: 200px; /* Increase image size */
  height: auto;
  margin: 20px;
  float: right;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
`;

const OvalBackground = styled(motion.div)`
  background-color: green;
  color: white;
  padding: 10px 20px;
  border-radius: 50px;
  margin: 20px 0;
  display: inline-block;
  width: auto;
  max-width: 300px;
  text-align: center;

  @media (min-width: 768px) {
    padding: 20px 40px;
  }
`;

const MissionTitle = styled(motion.h2)`
  font-size: 28px;
  line-height: 1.2;
  margin-bottom: 10px;
  color: green;
`;

const ValuesTitle = styled(motion.h2)`
  font-size: 28px;
  line-height: 1.2;
  margin-bottom: 10px;
  color: green;
`;

const imageVariants = {
  hover: { scale: 1.1, rotate: 5 },
  tap: { scale: 0.9 },
};

const AboutSection = () => (
  <AboutSectionContainer>
    <LeftSection>
      <AboutTitle
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1 }}
      >
        About Us
      </AboutTitle>
      <AboutText
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1 }}
      >
        We are a Professional cleaning, Property Management, Fumigation,
        landscaping and garbage collection services company in Kenya.
        Metrop Cleaning Services Ltd was incorporated in the year 2017, under the Company Act 486 Laws of Kenya to offer professional commercial and residential hygiene solutions in Kenya. Since our inception, we are strengthening our network countrywide to reach our customers at the grass-root level and across East Africa. We aim to offer the right solutions with the highest quality service delivery and customer satisfaction.
        <AboutImage
          src="/images/image2.png"
          alt="About Us Image"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
          whileHover="hover"
          whileTap="tap"
          variants={imageVariants}
        />
        <br /><br />
        We are specialists in Property Management, Commercial/Domestic cleaning, Fumigation/Pest control, Sanitary/hygiene, Landscaping/gardening, Garbage collection Services. We offer our services on same day service delivery as well as regular visits.
      </AboutText>
    </LeftSection>
    <RightSection>
      <MissionTitle
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1 }}
      >
        Our Mission
      </MissionTitle>
      <OvalBackground
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1 }}
      >
        To exceed our clients’ expectations on every occasion, whilst ensuring a satisfactory return to employees and investors alike.
      </OvalBackground>
      <ValuesTitle
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1 }}
      >
        Our Values
      </ValuesTitle>
      <OvalBackground
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1 }}
      >
        Creativity and innovation
        <br />
        Satisfactory return to employees and investors
        <br />
        Consistency with excellence
        <br />
        Accountable in resource utilization
        <br />
        Delivery Scalable Solutions
        <br />
        Hygiene means everything to us. Get your spaces sparkly clean with our tailor-made service
      </OvalBackground>
      <OvalBackground
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1, delay: 0.5 }}
      >
        Hygiene means everything to us. Get your spaces sparkly clean with our tailor-made service
      </OvalBackground>
    </RightSection>
  </AboutSectionContainer>
);

export default AboutSection;
