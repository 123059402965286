import React from 'react';
import styled, { keyframes } from 'styled-components';
import { FaWhatsapp } from 'react-icons/fa';

const WhatsAppButtonContainer = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #25D366;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.1);
  }
`;

const WhatsAppIcon = styled(FaWhatsapp)`
  color: white;
  font-size: 24px;
`;

const textAnimation = keyframes`
  0% { opacity: 0; transform: translateY(-10px); }
  50% { opacity: 1; transform: translateY(0); }
  100% { opacity: 0; transform: translateY(-10px); }
`;

const AnimatedText = styled.div`
  position: fixed;
  bottom: 80px;
  right: 20px;
  background-color: orange;
  color: darkgreen;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 14px;
  animation: ${textAnimation} 2s infinite;
`;

const WhatsAppButton = () => {
  const handleWhatsAppClick = () => {
    const phoneNumber = '+254741092877';
    try {
      window.open(`https://wa.me/${phoneNumber}`, '_blank');
    } catch (error) {
      console.error('Error opening WhatsApp:', error);
    }
  };

  return (
    <>
      <AnimatedText>Let's Chat</AnimatedText>
      <WhatsAppButtonContainer
        onClick={handleWhatsAppClick}
        aria-label="Open WhatsApp chat"
      >
        <WhatsAppIcon />
      </WhatsAppButtonContainer>
    </>
  );
};

export default WhatsAppButton;
