// src/components/Footer.js
import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const FooterContainer = styled.footer`
  background: #333;
  color: #fff;
  text-align: center;
  padding: 20px 0;
`;

const FooterText = styled.p`
  font-size: 16px;
`;

const SocialLinks = styled.div`
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 10px;
`;

const SocialLink = styled.a`
  color: #fff;
  font-size: 24px;
  transition: color 0.3s;
  &:hover {
    color: #ff9800;
  }
`;

const Footer = () => (
  <FooterContainer as={motion.footer} initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1 }}>
    <FooterText>&copy; 2024 METROP CLEANING SERVICES LTD. All rights reserved.</FooterText>
    <SocialLinks>
      <SocialLink href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
        <motion.i className="fab fa-facebook-f" whileHover={{ scale: 1.2 }} />
      </SocialLink>
      <SocialLink href="https://www.twitter.com" target="_blank" rel="noopener noreferrer">
        <motion.i className="fab fa-twitter" whileHover={{ scale: 1.2 }} />
      </SocialLink>
      <SocialLink href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
        <motion.i className="fab fa-instagram" whileHover={{ scale: 1.2 }} />
      </SocialLink>
      <SocialLink href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer">
        <motion.i className="fab fa-linkedin-in" whileHover={{ scale: 1.2 }} />
      </SocialLink>
    </SocialLinks>
  </FooterContainer>
);

export default Footer;
