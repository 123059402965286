import React from 'react';
import styled, { keyframes } from 'styled-components';
import { motion, useAnimation } from 'framer-motion';

const HeroSectionContainer = styled.section`
  display: flex;
  margin-top:40px;
  margin-left:-80px;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 0 0; 
  position: relative;
  overflow: hidden;
  background-image: url('/images/banner.png'); 
  background-size: cover; /* Ensures the image covers the entire section */
  background-position: center;
  background-attachment: scroll; /* Default value, ensure no issues with fixed */
  background-repeat: no-repeat; /* Prevents the image from repeating */
  width: 100vw; /* Ensure the section takes full width */
  min-height: 90vh; /* Ensure the section takes full height */

  @media (max-width: 767px) {
    /* Styles for small devices (up to 767px wide) */
    padding: 0; /* Further reduced padding for smaller screens */
    widht: 100%;
    margin:0;
  }
`;


const HeroContent = styled.div`
  margin: 0 auto;
  position: relative;
  z-index: 1; 
`;

const HeroText = styled(motion.h1)`
  font-size: 48px;
  line-height: 1.2;
  color: green;
  margin-top: 50px; /* Add this line to move the HeroText down */
`;

const HeroSubText = styled(motion.h2)`
  font-size: 24px;
  color: orange;

  line-height: 1.2;
  font-style: italic;
  margin-top: 30px; /* Add this line to move the HeroSubText down */
`;

const sparkleAnimation = keyframes`
  0% { transform: scale(1); opacity: 1; }
  50% { transform: scale(1.2); opacity: 0.5; }
  100% { transform: scale(1); opacity: 1; }
`;

const SparklingButton = styled(motion.button)`
  /* Base button styles */
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  outline: none;
  position: relative;
  overflow: hidden;
  z-index: 1;

  /* Glow effect */
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 300%;
    height: 300%;
    background-color: rgba(255, 255, 255, 0.5); /* Light color */
    border-radius: 50%;
    transition: width 0.5s ease, height 0.5s ease, opacity 0.5s ease;
    z-index: -1;
    transform: translate(-50%, -50%);
    animation: ${sparkleAnimation} 2s infinite; /* Apply animation */
  }
`;

const Sparkle = styled(motion.span)`
  position: absolute;
  width: 200%;
  height: 200%;
  background: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.5),
    transparent,
    rgba(255, 255, 255, 0.5)
  );
  top: -50%;
  left: -50%;
  animation: sparkle 2s linear infinite;
  @keyframes sparkle {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const SparklingText = styled(motion.span)`
  font-size: 36px;
  color: #FFA500;
  font-weight: bold;
  background: linear-gradient(45deg, rgba(255, 255, 255, 0.5), transparent, rgba(255, 255, 255, 0.5));
  animation: sparkle 2s linear infinite;
  @keyframes sparkle {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }
`;

const HeroSection = () => {
  const sparkleControls = useAnimation();

 

  const handleStartedClick = () => {
    const REACT_APP_WHATSAPP_NUMBER = '254741092877';
    const whatsappUrl = `https://wa.me/${REACT_APP_WHATSAPP_NUMBER}`;
    window.open(whatsappUrl, '_blank');
  };

  return (
    <HeroSectionContainer>
      <HeroContent>
        <HeroText
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0, transition: { type: 'spring', stiffness: 100 } }}
        >
          METROP CLEANING SERVICES LTD
        </HeroText>
        <HeroSubText
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0, transition: { type: 'spring', stiffness: 100, delay: 0.5 } }}
        >
          Professional Cleaning Services
        </HeroSubText>
        <SparklingButton
          onClick={handleStartedClick}
          style={{ backgroundColor: 'green' }}
          whileHover={{ scale: 1.1, backgroundColor: 'green', color: "#fff" }}
          whileTap={{ scale: 0.9 }}
        >
          Get In Touch
          <Sparkle animate={sparkleControls} />
        </SparklingButton>
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0, transition: { type: 'spring', stiffness: 100, delay: 1 } }}
        >
         Let Us Make it{' '}
          <SparklingText>
            Sparkling
          </SparklingText>{' '}
          Clean
        </motion.div>
      </HeroContent>
    </HeroSectionContainer>
  );
};

export default HeroSection;
