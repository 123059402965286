import React, { useEffect, useRef, useState } from 'react';
import Header from './components/Header';
import HeroSection from './components/HeroSection';
import Services from './components/Services';
import AboutSection from './components/AboutSection';
import Testimonials from './components/Testimonials';
import ContactForm from './components/ContactForm';
import Footer from './components/Footer';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { FaArrowUp } from 'react-icons/fa';
import WhatsAppButton from './components/WhatsAppButton';

const AppContainer = styled.div`
  font-family: ;
  widht :100;
  height: 100%;
  margin: 0 !important;
  padding: 0;
  color: black;
  background-color: none; /* Maintain existing background color */
  overflow-x: hidden;
`;

const MainContainer = styled(motion.div)`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  margin: 0 !important;
`;

const ContentContainer = styled.div`
  width: 100%;
  max-width: 1200px;
  padding: 0 20px;
  margin-top: 10px;
`;

const Section = styled.div`
  padding:0;
  margin: 0 !important;
  width: 100%;
  min-height: 100vh;
`;

const BackToTopButton = styled(motion.button)`
  position: fixed;
  bottom: 20px;
  right: 20px;
  background: #2e7d32; /* Green color */
  color: white;
  border: none;
  border-radius: 50%;
  padding: 15px;
  cursor: pointer;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  transition: background 0.3s;
  &:hover {
    background: #ff9800; /* Orange color on hover */
  }
  z-index: 1000;
`;



const App = () => {
  const sectionsRef = useRef([]);  
  const [showButton, setShowButton] = useState(false);
  const [isOpen, setIsOpen] = useState(false); // Add state for menu toggle

  useEffect(() => {
    const handleScroll = () => {
      setShowButton(window.scrollY > 300);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <AppContainer>
      {/* Pass isOpen and setIsOpen as props to Header */}
      <Header isOpen={isOpen} setIsOpen={setIsOpen} />
      <MainContainer
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <ContentContainer>
          <Section ref={(el) => (sectionsRef.current[0] = el)} id="home">
            <HeroSection />
          </Section>
          <Section ref={(el) => (sectionsRef.current[1] = el)} id="about">
            <AboutSection />
          </Section>
          <Section ref={(el) => (sectionsRef.current[2] = el)} id="services">
            <Services />
          </Section>
          <Section ref={(el) => (sectionsRef.current[3] = el)} id="testimonials">
            <Testimonials />
          </Section>
          <Section ref={(el) => (sectionsRef.current[4] = el)} id="contact">
            <ContactForm />
          </Section>
        </ContentContainer>
        {showButton && (
          <BackToTopButton
            onClick={scrollToTop}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.3 }}
          >
            <FaArrowUp />
          </BackToTopButton>
        )}
      </MainContainer>
      <WhatsAppButton phoneNumber="+254720465802" />
      <Footer />
    </AppContainer>
  );
};

export default App;
