import React, { useState } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import emailjs from 'emailjs-com';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ContactUsContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 20px;
  background: #ffcc80;
  color: #2e7d32;
  text-align: center;
`;

const ContactContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const ContactList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  margin-bottom: 50px;
  padding: 20px;
  background: #fff3e0;
  border-radius: 10px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
`;

const ContactItem = styled(motion.div)`
  display: flex;
  align-items: center;
  gap: 20px;
  background: white;
  padding: 15px 25px;
  border-radius: 10px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  width: 100%;
  transition: transform 0.3s;
  &:hover {
    transform: translateY(-10px);
  }
`;

const ContactText = styled.p`
  font-size: 16px;
  color: #555;
  margin: 0;
`;

const Icon = styled.div`
  font-size: 24px;
  color: #1b5e20;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 600px;
  margin-bottom: 50px;
`;

const Input = styled.input`
  padding: 10px;
  margin: 10px 0;
  width: 100%;
  border: 2px solid #2e7d32;
  border-radius: 5px;
  font-size: 16px;
`;

const TextArea = styled.textarea`
  padding: 10px;
  margin: 10px 0;
  width: 100%;
  border: 2px solid #2e7d32;
  border-radius: 5px;
  font-size: 16px;
  height: 100px;
`;

const Button = styled.button`
  padding: 10px 20px;
  margin: 10px;
  font-size: 16px;
  color: white;
  background: #2e7d32;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background: #1b5e20;
  }
`;

const contactData = [
  {
    icon: "📞",
    text: "0741092877",
  },
  {
    icon: "📞",
    text: "0736415464",
  },
  {
    icon: "✉️",
    text: "metropcleaningservices@gmail.com",
  },
  {
    icon: "🌐",
    text: "www.metropcleaningservicesltd.com",
  },
  {
    icon: "🏢",
    text: "Trance Towers, 2nd Floor, Mombasa Rd",
  },
];

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: '',
    senderEmail: '',
    phone: '',
    subject: '',
    message: '',
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const validateEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const sendNotification = async (e) => {
    e.preventDefault();

    if (!validateEmail(formData.senderEmail)) {
      toast.error('Please enter a valid email address.');
      return;
    }

    setIsSubmitting(true);

    try {
      const result = await emailjs.send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        {
          to_name: 'Maggy',
          from_name: formData.name,
          message: `${formData.message}\nPhone: ${formData.phone}`,
          subject: formData.subject,
          reply_to: formData.senderEmail,
        },
        process.env.REACT_APP_EMAILJS_USER_ID
      );
      console.log(result.text);
      toast.success('Notification sent successfully!');
    } catch (error) {
      console.error(error.text);
      toast.error('Failed to send notification. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleWhatsApp = () => {
    const { name, subject, message, phone } = formData;
    const text = `Name: ${name}\nSubject: ${subject}\nMessage: ${message}\nPhone: ${phone}`;
    window.open(`https://api.whatsapp.com/send?phone=254741092877&text=${encodeURIComponent(text)}`);
  };

  return (
    <ContactUsContainer id="contactus">
      <ToastContainer />
      <h2>Contact Us</h2>
      <p>Reach out to find out more about our professional services.</p>
      <ContactContent>
        <ContactList>
          {contactData.map((item, index) => (
            <ContactItem
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
            >
              <Icon>{item.icon}</Icon>
              <ContactText>{item.text}</ContactText>
            </ContactItem>
          ))}
        </ContactList>
        <Form onSubmit={sendNotification}>
          <Input
            type="text"
            name="name"
            placeholder="Your Name"
            value={formData.name}
            onChange={handleChange}
            required
          />
          <Input
            type="email"
            name="senderEmail"
            placeholder="Your Email"
            value={formData.senderEmail}
            onChange={handleChange}
            required
          />
          <Input
            type="tel"
            name="phone"
            placeholder="Your Phone Number"
            value={formData.phone}
            onChange={handleChange}
            required
          />
          <Input
            type="text"
            name="subject"
            placeholder="Subject"
            value={formData.subject}
            onChange={handleChange}
            required
          />
          <TextArea
            name="message"
            placeholder="Your Message"
            value={formData.message}
            onChange={handleChange}
            required
          />
          <Button type="submit" disabled={isSubmitting}>
            {isSubmitting ? 'Sending...' : 'Send Email'}
          </Button>
          <Button type="button" onClick={handleWhatsApp} disabled={isSubmitting}>
            WhatsApp
          </Button>
        </Form>
      </ContactContent>
    </ContactUsContainer>
  );
};

export default ContactUs;
