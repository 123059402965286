import React, { useState } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { FaBars } from 'react-icons/fa';

const HeaderContainer = styled.header`
  background-color: #ffcc80;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  backdrop-filter: blur(10px);

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
`;

const LogoImage = styled.img`
  width: 50px; /* Adjust the width as needed */
  height: auto; /* Maintain aspect ratio */
  margin-right: 10px; /* Adjust spacing as needed */
`;

const LogoText = styled.div`
  font-size: 24px;
  font-weight: bold;
  color: black;
  cursor: pointer;
  text-decoration: none;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const StyledLink = styled.a`
  color: inherit;
  text-decoration: none;
`;

const NavLinks = styled.ul`
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  justify-content: center;
  flex-grow: 1;

  @media (max-width: 768px) {
    display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
    flex-direction: column;
    position: absolute;
    top: 60px;
    left: 50%;
    transform: translateX(-50%);
    width: 200px;
    background: #ffa500;
    padding: 10px 0;
    z-index: 999;
    border-radius: 10px;
    transition: opacity 0.3s ease-in-out;
    opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
    pointer-events: ${({ isOpen }) => (isOpen ? 'auto' : 'none')};
  }
`;

const NavLink = styled.li`
  margin: 0 10px;

  a {
    text-decoration: none;
    color: #333;
    font-size: 18px;
    font-weight: bold;
    padding: 5px 10px;
    border-radius: 5px;
    transition: background-color 0.3s, color 0.3s;

    &:hover {
      background-color: #006400;
      color: white;
    }

    &:active {
      transition: transform 0.2s;
      transform: scale(0.95);
    }
  }

  @media (max-width: 768px) {
    margin: 10px 0;
    text-align: center;
  }
`;

const MenuToggle = styled.div`
  display: none;
  font-size: 32px;
  cursor: pointer;
  transition: color 0.3s;

  @media (max-width: 768px) {
    display: block;
    margin-right: 20px;
  }

  &:hover {
    color: #006400;
    & + ${NavLinks} {
      display: flex;
    }
  }
`;

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <HeaderContainer
      as={motion.div}
      initial={{ y: -100 }}
      animate={{ y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <LogoContainer>
        <LogoImage src="/images/logo.jpg" alt="Logo" />
        <LogoText><StyledLink href='/'>METROP CLEANING SERVICES LTD</StyledLink></LogoText>
      </LogoContainer>
      <MenuToggle onClick={handleClick}>
        <FaBars />
      </MenuToggle>
      <NavLinks isOpen={isOpen}>
        <NavLink><a href="/" onClick={handleClick}>Home</a></NavLink>
        <NavLink><a href="#about" onClick={handleClick}>About</a></NavLink>
        <NavLink><a href="#services" onClick={handleClick}>Services</a></NavLink>
        <NavLink><a href="#testimonials" onClick={handleClick}>Testimonials</a></NavLink>
        <NavLink><a href="#contact" onClick={handleClick}>Contact</a></NavLink>
      </NavLinks>
    </HeaderContainer>
  );
};

export default Header;
