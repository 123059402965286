import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const WhyChooseUsContainer = styled.section`
  padding: 100px 20px;
  text-align: center;
  background: linear-gradient(135deg, #ffcc80 30%, #ffb74d 100%);
  color: #004d40;
`;

const Title = styled(motion.h2)`
  font-size: 36px;
  margin-bottom: 40px;
  color: #004d40;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
`;

const SubTitle = styled(motion.h3)`
  font-size: 28px;
  margin-bottom: 20px;
  color: #00695c;
`;

const Paragraph = styled(motion.p)`
  font-size: 18px;
  max-width: 800px;
  margin: 0 auto 40px;
  color: #004d40;
`;

const FeatureList = styled.ul`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 40px;
  margin-bottom: 50px;
`;

const FeatureItem = styled(motion.li)`
  text-align: left;
  background: #ffffff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
`;

const FeatureItemTitle = styled.h3`
  font-size: 24px;
  margin-bottom: 10px;
  color: #00796b;
`;

const FeatureItemDescription = styled.p`
  font-size: 16px;
  color: #004d40;
`;

const WhyChooseUs = () => (
  <WhyChooseUsContainer>
    <Title
      initial={{ opacity: 0, y: -50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.8 }}
    >
      Why Choose Us
    </Title>
    <FeatureList>
      <FeatureItem
        initial={{ opacity: 0, x: -50 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.6, delay: 0.2 }}
      >
        <FeatureItemTitle>Professional Service</FeatureItemTitle>
        <FeatureItemDescription>Our experienced team provides high-quality cleaning services.</FeatureItemDescription>
      </FeatureItem>
      <FeatureItem
        initial={{ opacity: 0, x: -50 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.6, delay: 0.4 }}
      >
        <FeatureItemTitle>Customized Solutions</FeatureItemTitle>
        <FeatureItemDescription>We tailor our cleaning services to meet your specific needs.</FeatureItemDescription>
      </FeatureItem>
      <FeatureItem
        initial={{ opacity: 0, x: -50 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.6, delay: 0.6 }}
      >
        <FeatureItemTitle>Environmentally Friendly</FeatureItemTitle>
        <FeatureItemDescription>We use eco-friendly products to ensure a safe and clean environment.</FeatureItemDescription>
      </FeatureItem>
    </FeatureList>
    <SubTitle
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.8 }}
    >
      METROP AT WORK
    </SubTitle>
    <Paragraph
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.8 }}
    >
      We have dedicated, qualified, and experienced staff who are well conversant with the local market demand and sustaining the company’s success at its discretion and saving time and money through timely delivery of our services.
    </Paragraph>
    <SubTitle
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.8 }}
    >
      OUR CLIENTS
    </SubTitle>
    <Paragraph
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.8 }}
    >
      Our customers are the heart of our business at METROP. We are motivated and inspired every day to offer you an efficient cleaning maintenance option and Property Management that will enable you to remain focused on your core business.
    </Paragraph>
  </WhyChooseUsContainer>
);

export default WhyChooseUs;
