import React, { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const ServicesContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 50px 20px;
  background: linear-gradient(135deg, #e0f7fa 30%, #80deea 100%);
  color: #2e7d32;
`;

const ServiceTitle = styled.h2`
  font-size: 48px;
  margin-bottom: 30px;
  color: #004d40;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
`;

const ServiceSubtitle = styled.p`
  font-size: 18px;
  margin-bottom: 50px;
  max-width: 800px;
  color: #00695c;
`;

const ServiceList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
`;

const ServiceCard = styled(motion.div)`
  position: relative;
  background-image: ${({ backgroundImage }) => `url(${backgroundImage})`};
  background-size: cover;
  background-position: center;
  border-radius: 15px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  width: 380px;
  height: 380px;
  overflow: hidden;
  &:hover {
    transform: translateY(-10px);
  }
`;

const ServiceCardOverlay = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  opacity: 0;
  transition: opacity 0.3s;
  ${ServiceCard}:hover & {
    opacity: 1;
  }
`;

const ServiceCardTitle = styled.h3`
  font-size: 26px;
  margin-bottom: 15px;
  color: orange;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
`;

const ServiceCardDescription = styled.p`
  font-size: 16px;
  color: #fff;
  margin-bottom: 20px;
`;

const OrderButton = styled(motion.button)`
  background-color: #2e7d32;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  &:hover {
    background-color: #1b5e20;
  }
`;

const servicesData = [
  [
    {
      title: "Commercial Cleaning",
      description: "Comprehensive commercial cleaning for businesses of all types and sizes.",
      backgroundImage: "/images/commercial-cleaning.jpg",
    },
    {
      title: "Domestic Cleaning",
      description: "Professional home cleaning: regular or one-off, for all domestic needs.",
      backgroundImage: "/images/domestic-cleaning.jpg",
    },
    {
      title: "High Level Window Cleaning",
      description: "Expert window cleaning for all glass surfaces: internal and external.",
      backgroundImage: "/images/window.jpg",
    },
    {
      title: "Car Cleaning",
      description: "Top-notch cleaning services for your vehicles.",
      backgroundImage: "/images/car.jpg",
    },  
    {
      title: "Sanitary Hygiene Solutions",
      description: "Affordable hygiene solutions across Kenya: washroom services, supplies, and installations.",
      backgroundImage: "/images/sanitary.jpg",
    },
    {
      title: "Carpet Cleaning",
      description: "Thorough cleaning for your Carpets",
      backgroundImage: "/images/carpet.jpg",
    },
    {
      title: "Landscaping and Gardening",
      description: "Professional landscaping transforms rugged terrains into beautiful, manicured lawns and gardens.",
      backgroundImage: "/images/landscaping.jpg",
    },
    {
      title: "Residential Cleaning",
      description: "Thorough cleaning for your Home/House",
      backgroundImage: "/images/residential.jpg",
    },
    {
      title: "Fumigation and Pest Control",
      description: "Effective pest control and fumigation using eco-friendly products to eliminate termites, cockroaches, ants, bedbugs, mites, and woodworms.",
      backgroundImage: "/images/fumigations.jpg",
    },
    {
      title: "Garbage Collection",
      description: "We offer reliable, eco-friendly garbage collection and waste management for corporate and residential clients in Nairobi.",
      backgroundImage: "/images/garbage_collection.jpg",
    },
  ],
];



const Services = () => {
  const [page, setPage] = useState(0);

  const handleWhatsApp = (title, description) => {
    const text = `Service: ${title}\nDescription: ${description}`;
    window.open(`https://api.whatsapp.com/send?phone=254741092877&text=${encodeURIComponent(text)}`);
  };

  const handleScroll = useCallback(() => {
    const scrollTop = window.scrollY;
    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;

    const scrolledToBottom = scrollTop + windowHeight >= documentHeight - 50;

    if (scrolledToBottom && page < servicesData.length - 1) {
      setPage(page + 1);
    }
  }, [page]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  return (
    <ServicesContainer>
      <ServiceTitle>Our Services</ServiceTitle>
      <ServiceSubtitle>
        Metrop Cleaning Services Ltd delivers a wide range of self-performed services that can be combined in numerous ways according to the customer's requirements.
      </ServiceSubtitle>
      <ServiceList>
        {servicesData[page].map((service, index) => (
          <ServiceCard
            key={index}
            whileHover={{ scale: 1.05 }}
            backgroundImage={service.backgroundImage}
          >
            <ServiceCardOverlay
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5 }}
            >
              <ServiceCardTitle>{service.title}</ServiceCardTitle>
              <ServiceCardDescription>{service.description}</ServiceCardDescription>
              <OrderButton
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                onClick={() => handleWhatsApp(service.title, service.description)}
              >
                Enquire
              </OrderButton>
            </ServiceCardOverlay>
          </ServiceCard>
        ))}
      </ServiceList>
    </ServicesContainer>
  );
};

export default Services;
